<template>
  <div
    class="content_body AppointmentView position_relative"
    v-loading="loading"
  >
    <!-- 预约看板 状态 及 时间修改  -->
    <div
      class="position_absolute appointmentDesClass"
      v-show="handleIndex == 0"
    >
      <el-row class="dis_flex flex_y_center ">
        <el-col :span="8" class="dis_flex font_13">
          <div
            class="squareBlock_orange marrt_10"
            style="height:20px;width:20px"
          ></div>
          <span class="font_13 color_333">未到店</span>

          <div
            class="squareBlock_gray marrt_10 marlt_20"
            style="height:20px;width:20px"
          ></div>
          <span class="font_13 color_333">已完成</span>
        </el-col>
        <el-col :span="10">
          <div class="dis_flex flex_y_center">
            <div class="pad_10" @click="upClick">
              <i class="el-icon-arrow-left color_333"></i>
            </div>
            <div>
              <el-date-picker
                v-model="currentDate"
                type="date"
                placeholder="选择日期"
                size="small"
                @change="getAppointmentBillAll"
              >
              </el-date-picker>
            </div>
            <div class="pad_10" @click="downClick">
              <i class="el-icon-arrow-right color_333"></i>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 添加预约按钮 -->
    <div class="position_absolute addAppointmentBtn">
      <el-button type="primary" size="small" @click="addAppointment(0)"
        >添加预约
      </el-button>
    </div>
    <div class="">
      <el-tabs v-model="handleIndex" @tab-click="handleClick">
        <el-tab-pane label="预约看板" name="0">
          <div class="container_out">
            <div class="container">
              <div class="left_div">
                <div class="left_div2">
                  <table class="table left_table2">
                    <tbody class="tbody">
                      <tr
                        class="tr"
                        v-for="(item, index) in dataTime"
                        :key="index"
                      >
                        <td class="td">{{ item.timeStr }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="right_div">
                <div class="right_div1">
                  <div
                    class="table right_table1"
                    :style="{ width: scrollWidth + 'px' }"
                  >
                    <div
                      class="tr_div"
                      v-for="(item, index) in empList"
                      :key="index"
                    >
                      <div class="td_div back_tab font_14">
                        {{ item.EmployeeName }}
                        <span
                          v-if="
                            item.AppointmentScheduleName != null &&
                              item.AppointmentScheduleName != undefined
                          "
                        >
                          ({{ item.AppointmentScheduleName }})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_div2" @scroll.passive="changeScroll">
                  <div
                    class="right_table2"
                    :style="{
                      width: scrollWidth + 'px',
                      height: dataTime.length * 41 + 'px',
                    }"
                  >
                    <div
                      class="tr_div tr_div_right2"
                      v-for="(item, index) in empList"
                      :key="index"
                    >
                      <div
                        class="td_div td_div_right2"
                        v-for="(i, n) in item.curr"
                        :key="n"
                        :id="[i.Disabled ? 'right2_td_div' : '']"
                      >
                        <div
                          class="divider"
                          v-if="i.dividerState"
                          :style="{ width: dividerWidth }"
                        ></div>

                        <div
                          :class="[
                            i.height <= 0 && i.showState
                              ? 'add_appointment'
                              : 'normal_box',
                          ]"
                          @mouseenter="mouseOver(index, n, item, i)"
                          @mouseleave="mouseLeave(index, n, item, i)"
                          @click="clickAddAppointment(i)"
                        >
                          {{ i.height <= 0 && i.showState ? "添加预约" : "" }}
                        </div>
                        <div
                          :class="[
                            i.Status == 10
                              ? 'td_show'
                              : i.Status == 20
                              ? 'select_td_show'
                              : '',
                          ]"
                          :style="{
                            height: val.height + 'px',
                            left: val.left + 'px',
                            width: val.width + 'px',
                          }"
                          v-for="(val, valIndex) in i.currentArr"
                          :key="valIndex"
                        >
                          <div
                            class="tdd"
                            v-if="val.height > 0"
                            @mouseenter="showDiv(val, n, index)"
                            @mouseleave="hideDiv(val, n)"
                            :style="{
                              'border-bottom': i.showBottom
                                ? '2px solid #F5B8F5'
                                : '',
                            }"
                          >
                            <div class="dis_flex flex_y_center">
                              <span class="marlt_10 font_12 col_333">
                                {{ val.CustomerName }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 预约详情 弹窗界面 -->
                    <div
                      class="pad_10 appointment_details "
                      :class="
                        continueIndex == empList.length - 1
                          ? 'popper__arrow_right'
                          : 'popper__arrow_left'
                      "
                      v-show="isContinueShow"
                      :style="positionStyle"
                      @mouseenter="onMouseenterDetailView"
                      @mouseleave="onMouseleaveDetailView"
                    >
                      <el-row>
                        <el-col :span="12" class="text_left font_16 color_333">
                          {{ appointDetails.CustomerName }}
                        </el-col>
                        <el-col :span="12" class="text_right font_15 color_333">
                          {{ appointDetails.Type == "10" ? "点单" : "排单" }}
                        </el-col>
                      </el-row>

                      <el-row class="martp_5 font_12 color_666">
                        <el-col :span="24">{{
                          appointDetails.PhoneNumber
                        }}</el-col>
                      </el-row>

                      <div class="dis_flex flex_wrap martp_10">
                        <div
                          class="marrt_10 pad_5_15 border_blue martp_10 back_F6F7FF color_blue font_10 radius5"
                          v-for="(item, index) in appointDetails.Project"
                          :key="index"
                        >
                          {{ item.ProjectName }}
                        </div>
                      </div>

                      <el-row
                        class="martp_10 text_left"
                        v-if="appointDetails.Remark"
                      >
                        <el-col :span="24" class="color_666 font_10">
                          备注:
                        </el-col>
                        <el-col
                          :span="24"
                          class="martp_10 back_F5 pad_10 color_666 font_12"
                        >
                          {{ appointDetails.Remark }}
                        </el-col>
                      </el-row>

                      <div
                        class="pad_10_0 dis_flex flex_x_between"
                        v-if="appointDetails.Status != 20"
                      >
                        <el-button
                          @click="updateStatus(appointDetails, 0)"
                          size="small"
                          >取消
                        </el-button>
                        <el-button
                          @click.stop.prevent="turnStaff(appointDetails)"
                          size="small"
                        >
                          编辑
                        </el-button>
                        <el-button
                          @click="updateStatus(appointDetails, 1)"
                          size="small"
                          >确认到店
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="预约明细" name="1">
          <div>
            <el-row class="border_bottom">
              <el-col :span="24">
                <el-form
                  :inline="true"
                  size="small"
                  @keyup.enter.native="handleSearch"
                >
                  <el-form-item label="客户">
                    <el-input
                      v-model="searchValue"
                      placeholder="输入顾客名称、手机号、编号搜索"
                      clearable
                      @clear="handleSearch"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="预约编号">
                    <el-input
                      v-model="AppointmentBillID"
                      placeholder="输入预约编号搜索"
                      clearable
                      @clear="handleSearch"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="状态">
                    <el-select
                      placeholder="请选择"
                      clearable
                      v-model="statusValue"
                      @change="handleSearch"
                    >
                      <el-option label="未到店" value="10"></el-option>
                      <el-option label="已完成" value="20"></el-option>
                      <el-option label="已取消" value="30"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="预约时间">
                    <el-date-picker
                      v-model="SearchData"
                      type="daterange"
                      range-separator="至"
                      value-format="yyyy-MM-dd"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="handleSearch"
                    ></el-date-picker>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="handleSearch"
                      v-prevent-click
                      >搜索</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>

            <el-table
              :data="tableData"
              style="width: 100%"
              class="martp_10"
              size="small"
            >
              <el-table-column label="顾客信息">
                <template slot-scope="scope">
                  <div class="marrt_10">{{ scope.row.CustomerName }}</div>
                  <div>{{ scope.row.PhoneNumber }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="EmployeeName"
                label="接待人"
              ></el-table-column>
              <el-table-column prop="Type" label="点单/排单">
                <template slot-scope="scope">
                  {{ scope.row.Type == 10 ? "点单" : "排单" }}
                </template>
              </el-table-column>
              <el-table-column prop="Status" label="状态">
                <template slot-scope="scope">
                  {{
                    scope.row.Status == 10
                      ? "未到店"
                      : scope.row.Status == 20
                      ? "已完成"
                      : "已取消"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="AppointmentDate"
                label="预约时间"
              ></el-table-column>
              <el-table-column
                prop="Period"
                label="预约时长（分钟）"
              ></el-table-column>
              <el-table-column label="操作" width="80" size="small">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="updateData(scope.row)"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination
                background
                v-if="paginations.total > 0"
                @current-change="handleCurrentChange"
                :current-page.sync="paginations.page"
                :page-size="paginations.page_size"
                :layout="paginations.layout"
                :total="paginations.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="martp_10"></div>
    <!--新增 修改-->
    <el-dialog :visible.sync="appointmentDialogShow" width="1000px">
      <span slot="title" class="font_18">{{
        appointmentDetailsTitleState == 0 ? "编辑预约" : "添加预约"
      }}</span>
      <!-- 预约顾客信息  -->
      <el-row>
        <el-col :span="11">
          <el-autocomplete
            popper-class="customer-autocomplete"
            prefix-icon="el-icon-user-solid"
            v-model="customerName"
            style="width: 100%"
            placeholder="请输入会员名称、手机号、编号"
            :fetch-suggestions="saleCustomerData"
            @select="handleCustomerSelect"
            :disabled="CustomerID != null"
            :trigger-on-focus="false"
            :hide-loading="true"
            :highlight-first-item="true"
            :select-when-unmatched="true"
            size="small"
          >
            <template slot="append">
              <el-button
                icon="el-icon-delete"
                @click="removeCustomer"
                :disabled="appointmentDetailsTitleState == 0"
              ></el-button>
            </template>
            <template slot-scope="{ item }">
              <div class="name">
                {{ item.Name }}
                <el-tag size="mini" v-if="item.CustomerLevelName"
                  >{{ item.CustomerLevelName }}
                </el-tag>
              </div>
              <div class="info">手机号：{{ item.PhoneNumber }}</div>
              <span class="info" v-if="item.Code"
                >客户编号：{{ item.Code }}</span
              >
            </template>
          </el-autocomplete>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
          class="back_f8 dis_flex flex_y_center  radius5 line_height_38 pad_0_10"
        >
          <el-col :span="12" class="back_f8">预约项目</el-col>
          <el-col :span="12" class="text_right">
            <el-button
              type="text"
              size="small"
              @click="addAppointmentProject"
              :disabled="
                (currentItme.Status == '30' || currentItme.Status == '20') &&
                  handleIndex == '1'
              "
              >添 加
            </el-button>
          </el-col>
        </el-col>
      </el-row>

      <el-row class="martp_10">
        <el-col :span="11" class="left_item">
          <div class="back_f8 pad_10" style="height:400px">
            <div class="martp_5">
              <el-form
                :model="AppointmentInfoRuleForm"
                :rules="AppointmentInfoRules"
                ref="AppointmentInfoRuleForm"
                label-width="100px"
                class="demo-ruleForm"
                size="small"
              >
                <el-form-item
                  label="接待人:"
                  prop="EmployeeID"
                  v-if="IsMustEmployee"
                >
                  <el-col :span="20">
                    <el-select
                      v-model="AppointmentInfoRuleForm.EmployeeID"
                      placeholder="请选择接待人"
                      :disabled="
                        (currentItme.Status == '30' ||
                          currentItme.Status == '20') &&
                          handleIndex == '1'
                      "
                      clearable
                    >
                      <el-option
                        :label="item.EmployeeName"
                        :value="item.EmployeeID"
                        v-for="(item, index) in employeeList"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="接待人:" v-else>
                  <el-col :span="20">
                    <el-select
                      v-model="AppointmentInfoRuleForm.EmployeeID"
                      placeholder="请选择接待人"
                      clearable
                      :disabled="
                        (currentItme.Status == '30' ||
                          currentItme.Status == '20') &&
                          handleIndex == '1'
                      "
                    >
                      <el-option
                        :label="item.EmployeeName"
                        :value="item.EmployeeID"
                        v-for="(item, index) in employeeList"
                        :key="index"
                        :disabled="
                          (currentItme.Status == '30' ||
                            currentItme.Status == '20') &&
                            handleIndex == '1'
                        "
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="接待人状态:" prop="Type">
                  <el-radio-group
                    v-model="AppointmentInfoRuleForm.Type"
                    :disabled="
                      (currentItme.Status == '30' ||
                        currentItme.Status == '20') &&
                        handleIndex == '1'
                    "
                  >
                    <el-radio label="10">点单</el-radio>
                    <el-radio label="20">排单</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="预约时间:" required>
                  <el-col :span="20">
                    <el-form-item prop="AppointmentDate">
                      <el-date-picker
                        type="date"
                        placeholder="选择日期"
                        v-model="AppointmentInfoRuleForm.AppointmentDate"
                        style="width: 100%"
                        value-format="yyyy-MM-dd"
                        :picker-options="expireTimeOption"
                        :disabled="
                          (currentItme.Status == '30' ||
                            currentItme.Status == '20') &&
                            handleIndex == '1'
                        "
                      >
                      </el-date-picker>
                      <el-time-select
                        :disabled="
                          (currentItme.Status == '30' ||
                            currentItme.Status == '20') &&
                            handleIndex == '1'
                        "
                        clearable
                        v-model="AppointmentInfoRuleForm.AppointmentTime"
                        :picker-options="{
                          start: StartTimeData,
                          step: Period,
                          end: EndTimeData,
                        }"
                        @focus="getTimeArr"
                        @change="confirmTime"
                        placeholder="选择时间"
                        class="martp_10"
                        style="width: 100%"
                      >
                      </el-time-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
                <el-form-item label="预约时长:" prop="Period">
                  <el-col :span="20">
                    <el-select
                      v-model="AppointmentInfoRuleForm.Period"
                      placeholder="请选择预约时长"
                      :disabled="
                        (currentItme.Status == '30' ||
                          currentItme.Status == '20') &&
                          handleIndex == '1'
                      "
                      clearable
                    >
                      <el-option
                        :label="item.time"
                        :value="item.value"
                        v-for="(item, index) in timeArr"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item
                  label="预约状态:"
                  v-if="
                    appointmentDetailsTitleState == 0 &&
                      AppointmentInfoRuleForm.Status != '30'
                  "
                >
                  <el-radio-group
                    v-model="AppointmentInfoRuleForm.Status"
                    :disabled="
                      (currentItme.Status == '30' ||
                        currentItme.Status == '20') &&
                        handleIndex == '1'
                    "
                  >
                    <el-radio label="10">未到店</el-radio>
                    <el-radio label="20">已完成</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="备注信息:" prop="Remark">
                  <el-col :span="20">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="AppointmentInfoRuleForm.Remark"
                      placeholder="200字以内备注"
                      :disabled="
                        (currentItme.Status == '30' ||
                          currentItme.Status == '20') &&
                          handleIndex == '1'
                      "
                    >
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
        <el-col :span="11" :offset="2" class="right_item">
          <div style="height:400px" class="back_f8 pad_10">
            <el-scrollbar class="el-scrollbar_height" style="height:100%">
              <div
                class="dis_flex flex_x_between pad_10 flex_y_center"
                v-for="(item, index) in appointmentProjectList"
                :key="index"
              >
                <span>{{ item.Name || item.ProjectName }}</span>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>

      <span
        slot="footer"
        class="dialog-footer"
        v-if="appointmentDetailsTitleState == 0"
      >
        <el-button
          @click="cancelAppointment"
          size="small"
          :disabled="
            (currentItme.Status == '30' || currentItme.Status == '20') &&
              handleIndex == '1'
          "
          >取消预约</el-button
        >
        <el-button
          @click="saveAppointment"
          size="small"
          :disabled="
            (currentItme.Status == '30' || currentItme.Status == '20') &&
              handleIndex == '1'
          "
          >保存</el-button
        >
        <el-button
          type="primary"
          @click="clickPutOrder"
          size="small"
          :disabled="
            (currentItme.Status == '30' || currentItme.Status == '20') &&
              handleIndex == '1'
          "
          >开单</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="appointmentDialogShow = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveAppointment" size="small"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!--选择项目-->
    <el-dialog
      :visible.sync="selectProjectDialogState"
      title="选择预约项目"
      width="900px"
    >
      <template>
        <el-row>
          <el-col :span="8">
            <el-input
              placeholder="输入项目名称进行搜索"
              v-model="filterText"
              size="small"
              clearable
            ></el-input>
            <el-scrollbar class="el-scrollbar_height martp_5">
              <el-tree
                class="filter-tree"
                :data="projectList"
                show-checkbox
                node-key="PID"
                ref="treeRef"
                accordion
                highlight-current
                :props="defaultProps"
                :default-checked-keys="defaultCheckedKeysApplyApp"
                :filter-node-method="filterNode"
                @check="selectApplicableItems"
              >
                <span slot-scope="{ node, data }">
                  <span>{{ data.Name }}</span>
                  <el-tag
                    plain
                    class="marlt_5"
                    size="mini"
                    v-if="!data.IsProject"
                    >分类</el-tag
                  >
                </span>
              </el-tree>
            </el-scrollbar>
          </el-col>
          <el-col :span="15" :offset="1" class="border_left">
            <el-table
              size="small"
              :data="
                selectedTableData.filter(
                  (data) =>
                    !filterText ||
                    data.Name.toLowerCase().includes(filterText.toLowerCase())
                )
              "
              max-height="500px"
            >
              <el-table-column prop="Name" label="项目名称"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="small"
                    @click="deleteSelectRow(scope.row, scope.$index)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="selectProjectDialogState = false"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="confirmProjectSelect"
          v-prevent-click
          >确 认</el-button
        >
      </div>
    </el-dialog>
    <!--新增客户-->
    <!-- <el-dialog title="新增客户" :visible.sync="isAddCustom" width="850px">
      <div>
        <el-form
          :model="customer"
          :AppointmentInfoRules="customerRules"
          ref="customer"
          label-width="90px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员姓名" prop="Name">
                <el-input
                  v-model="customer.Name"
                  placeholder="请输入会员姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号码" prop="PhoneNumber">
                <el-input
                  v-model="customer.PhoneNumber"
                  maxlength="11"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员编号">
                <el-input
                  v-model="customer.Code"
                  placeholder="请输入会员编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员来源">
                <el-cascader
                  v-model="customer.CustomerSourceID"
                  :options="customerSource"
                  :props="{
                    checkStrictly: true,
                    children: 'Child',
                    value: 'ID',
                    label: 'Name',
                    emitPath: false,
                  }"
                  :show-all-levels="false"
                  clearable
                  filterable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属顾问">
                <el-select
                  v-model="customer.EmployeeID"
                  placeholder="请选择所属顾问"
                  clearable
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in employee"
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级">
                <el-select
                  v-model="customer.CustomerLevelID"
                  placeholder="请选择会员等级"
                  clearable
                  filterable
                  size="small"
                >
                  <el-option
                    v-for="item in customerLevel"
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员性别">
                <el-radio-group v-model="customer.Gender">
                  <el-radio label="2">女</el-radio>
                  <el-radio label="1">男</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="isAddCustom = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submitCustomer"
          :loading="modalLoading"
          v-prevent-click
          >保存</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import API from "@/api/iBeauty/Appointment/appointmentView";
import APIConfig from "@/api/iBeauty/Appointment/appointmentConfig";
import APICustomer from "@/api/iBeauty/Order/saleGoods";
import APIEmployee from "@/api/CRM/Customer/customer";
import APIAccount from "@/api/account";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import validate from "@/components/js/validate";
import permission from "@/components/js/permission";
import appointmentRecordVue from "../../../components/iBeauty/Customer/appointmentRecord.vue";
var dayjs = require("dayjs");
import date from "@/components/js/date";

var Enumerable = require("linq");

export default {
  name: "AppointmentView",

  data() {
    return {
      loading: false, // 加载状态
      modalLoading: false, // 新增顾客加载状态
      handleIndex: "0", // 当前tab
      searchValue: "", // 搜索值
      SearchData: ["", ""], // 预约时间
      statusValue: "", // 状态
      AppointmentBillID: "",
      CustomerID: null, // 顾客ID
      appointmentDialogShow: false, // 预约弹框展示状态
      selectProjectDialogState: false, // 选择项目弹框展示状态
      appointmentDetailsTitleState: "", // 预约详情弹框标题标识符
      projectClassification: "", // 项目分类
      projectSearch: "", // 查找项目
      appointmentProjectList: [], // 预约项目列表
      projectList: [], // 项目列表数据
      currentProject: [], // 当前所选中的项目
      timeArr: [], // 预约时长数据
      employeeList: [], // 可预约员工列表
      empList: [], // 员工和顾客预约信息
      tableData: [], // 预约列表
      customerName: "", // 新增预约搜索
      filterText: "",
      defaultCheckedKeysApplyApp: [], // 默认选中的适用项目节点
      selectedTableData: [],
      appointDetails: {
        showDetails: false,
      }, // 顾客预约详情
      dataTime: [], // 时间
      Period: "", // 预约间隔
      periodArr: [],
      currentDate: "", // 当前时间
      scrollWidth: 150,
      interval: 0,
      dividerWidth: "", // 分割线宽度
      StartTime: "", // 开始时间
      EndTime: "", // 结束时间
      StartTimeData: "", // 开始时间(选择时间)
      EndTimeData: "", // 结束时间(选择时间)
      cancelState: false, // 取消预约状态
      IsMustEmployee: true, // 添加预约时是否必选员工
      openBillState: true, // 是否可开单
      currentItme: {}, // 当前编辑数据
      isAddCustom: false,
      customerSource: [], //会员来源
      employee: [], //营销顾问
      customerLevel: [], //顾客等级
      isContinueShow: false, // 是否继续显示 预约详情
      continueIndex: 0,
      defaultProps: {
        children: "Child",
        label: "Name",
      },
      AppointmentInfoRuleForm: {
        ID: "", // 预约ID
        CustomerID: "", // 顾客ID
        EmployeeID: "", // 接待人ID
        AppointmentDate: new Date(), // 预约日期
        AppointmentTime: "", // 预约时间
        Type: "", // 接待人状态
        Status: "", // 审批状态
        Period: "", // 预约时长
        Remark: "", // 备注
      },
      AppointmentInfoRules: {
        EmployeeID: [
          { required: true, message: "请选择接待人", trigger: "change" },
        ],
        AppointmentDate: [
          { required: true, message: "请选择预约日期", trigger: "change" },
        ],
        AppointmentTime: [
          { required: true, message: "请选择预约时间", trigger: "change" },
        ],
        Type: [
          { required: true, message: "请选择接待人状态", trigger: "change" },
        ],
      },
      customer: {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      },
      customerRules: {
        Name: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
        PhoneNumber: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [
          { required: true, message: "请选择客户性别", trigger: "change" },
        ],
        CustomerSourceID: [
          { required: true, message: "请选择客户来源", trigger: "change" },
        ],
        EmployeeID: [
          { required: true, message: "请选择顾问", trigger: "change" },
        ],
        CustomerLevelID: [
          { required: true, message: "请选择客户等级", trigger: "change" },
        ],
        Code: [{ required: true, message: "请输入客户编号", trigger: "blur" }],
      },
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },

      position: {
        x: undefined,
        y: undefined,
      },
    };
  },
  computed: {
    /**    */
    positionStyle() {
      var that = this;
      return `top:${that.position.y}px;left:${that.position.x}px;`;
    },
  },
  methods: {
    // 切换tabs
    handleClick(type) {
      var that = this;
      if (that.handleIndex == "0") {
        that.appointmentBillAll();
      } else {
        that.getAppointmentBillList();
      }
    },
    // 搜索
    handleSearch() {
      var that = this;
      that.paginations.page = 1;
      that.getAppointmentBillList();
    },
    // 编辑
    updateData(data) {
      console.log("updateData -> data", data);
      var that = this;
      var ID = data.ID;
      that.currentItme = data;
      that.customerName = data.CustomerName + "【" + data.PhoneNumber + "】";
      that.CustomerID = data.CustomerID;
      that.AppointmentInfoRuleForm.ID = ID;
      that.appointmentDetailsTitleState = 0;
      that.appointmentDialogShow = true;
      that.getAppointmentBillInfo();
    },
    // 添加预约
    addAppointment(type, item) {
      var that = this;
      var type = type;
      that.appointmentDetailsTitleState = 1;
      that.AppointmentInfoRuleForm.ID = "";
      that.AppointmentInfoRuleForm.CustomerID = "";
      if (type == 1) {
        // 看板新增
        that.AppointmentInfoRuleForm.EmployeeID = item.EmployeeID;
        that.AppointmentInfoRuleForm.AppointmentDate = that.currentDate;
        that.AppointmentInfoRuleForm.AppointmentTime = item.StartTime;
      } else {
        // 顶部 新增按钮新增
        that.AppointmentInfoRuleForm.EmployeeID = "";
        that.AppointmentInfoRuleForm.AppointmentDate = date.formatDate.format(
          new Date(),
          "YYYY-MM-DD"
        );
        that.AppointmentInfoRuleForm.AppointmentTime = "";
      }
      that.AppointmentInfoRuleForm.Type = "10";
      that.AppointmentInfoRuleForm.Status = "";
      that.AppointmentInfoRuleForm.Period = that.timeArr[0].value;
      that.AppointmentInfoRuleForm.Remark = "";
      that.appointmentProjectList = [];

      that.customerName = "";
      that.CustomerID = null;
      that.appointmentDialogShow = true;
    },
    // 适用项目弹框搜索事件
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },

    // 选择时间
    getTimeArr() {
      var that = this;
      var currentDate = new Date();

      let selTime = date
        .convertDateFromString(that.AppointmentInfoRuleForm.AppointmentDate)
        .getTime();
      let currentTime = date
        .convertDateFromString(
          date.formatDate.format(currentDate, "YYYY-MM-DD")
        )
        .getTime();

      if (selTime == currentTime) {
        // 今天
        let minutes = parseInt(date.formatDate.format(currentDate, "mm"));
        var startIndex = parseInt(minutes / that.interval);
        var tempMinutes = that.periodArr[startIndex];

        if (tempMinutes == 60) {
          let hour = date.formatDate.format(
            new Date(new Date().setHours(new Date().getHours() + 1)),
            "hh"
          );
          that.StartTimeData = hour + ":" + "00";
          that.EndTimeData = that.EndTime;
        } else {
          let hours = date.formatDate.format(currentDate, "hh");
          that.StartTimeData = hours + ":" + tempMinutes;
          that.EndTimeData = that.EndTime;
        }
      } else if (selTime > currentTime) {
        that.StartTimeData = that.StartTime;
        that.EndTimeData = that.EndTime;
      }
    },

    // 选择时间确认
    confirmTime(date) {
      var that = this;
      if (date != null) {
        that.AppointmentInfoRuleForm.AppointmentTime = date;
      }
    },
    // 顾客数据
    saleCustomerData(queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      APICustomer.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 选择顾客
    handleCustomerSelect(item) {
      if (item.ID != undefined) {
        this.CustomerID = item.ID;
        this.customerFullName = item.Name;
        this.customerPhoneNumber = item.PhoneNumber;
        this.customerName = item.Name + "【" + item.PhoneNumber + "】";
      } else {
        // this.addNewCustomer();
      }
    },
    // 新增顾客
    addNewCustomer: function() {
      var that = this;
      that.customer = {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      };
      that.isAddCustom = true;
      that.employeeData();
    },
    // 客户所属顾问
    employeeData: function() {
      var that = this;
      APIEmployee.getConsultant()
        .then((res) => {
          if (res.StateCode == 200) {
            that.employee = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 顾客等级
    CustomerLevelData: function() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 保存新增客户
    submitCustomer: function() {
      var that = this;
      this.$refs.customer.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.customer);
          APIEmployee.createCustomer(para)
            .then(function(res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.CustomerID = res.Data.ID;
                that.customerFullName = res.Data.Name;
                that.customerPhoneNumber = res.Data.PhoneNumber;
                that.customerName =
                  res.Data.Name + "【" + res.Data.PhoneNumber + "】";
                that.isAddCustom = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        }
      });
    },
    // 取消当前顾客选择
    removeCustomer() {
      this.CustomerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      // this.customerChange();
    },
    // 添加预约项目
    addAppointmentProject() {
      var that = this;
      that.selectedTableData = Object.assign([], that.appointmentProjectList);
      that.defaultCheckedKeysApplyApp = [];
      // that.appointmentProjectList.forEach(val => {
      //     that.defaultCheckedKeysApplyApp.push(val.ID)
      // });
      that.selectProjectDialogState = true;
      that.$nextTick(() => {
        var defaultCheckedKeys = Enumerable.from(that.appointmentProjectList)
          .select((val) => "1" + val.ID)
          .toArray();
        that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
      });
    },
    // 选择适用项目事件
    selectApplicableItems(item, list) {
      var that = this;
      that.selectedTableData = Enumerable.from(list.checkedNodes)
        .where(function(i) {
          return i.IsProject;
        })
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          PID: item.PID,
          ParentID: item.ParentID,
          Price: item.Price,
          ProjectCategoryName: item.ProjectCategoryName,
          TreatTime: item.TreatTime,
        }))
        .toArray();
    },
    // 选择项目确认
    confirmProjectSelect() {
      var that = this;
      // var appointmentProjectList = Object.assign([], that.appointmentProjectList);
      var selectedTableData = Object.assign([], that.selectedTableData);
      if (that.selectedTableData.length == 0) {
        that.$message.error("请选择项目");
        return false;
      } else {
        var totalPeriod = 0;
        // that.appointmentProjectList = appointmentProjectList.concat(selectedTableData);
        that.appointmentProjectList = Object.assign([], selectedTableData);
        that.selectProjectDialogState = false;
        that.appointmentProjectList.forEach((val) => {
          totalPeriod += val.TreatTime;
        });
        for (let i = 0; i <= that.timeArr.length - 1; i++) {
          if (that.timeArr[i].value >= totalPeriod) {
            that.AppointmentInfoRuleForm.Period = that.timeArr[i].value;
            break;
          }
        }
      }
      console.log(that.AppointmentInfoRuleForm.Period);
      console.log(that.appointmentProjectList);
    },
    // 删除所选中的适用项目
    deleteSelectRow(row, index) {
      var that = this;
      var ParentID = row.ParentID;
      that.selectedTableData.splice(index, 1);
      // that.selectedTableData.forEach((val, index) => {
      //   if (val.ID == ParentID) {
      //     that.selectedTableData.splice(index, 1);
      //   }
      // });
      that.$nextTick(() => {
        var defaultCheckedKeys = Enumerable.from(that.selectedTableData)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
      });
    },

    // 获取预约列表
    getAppointmentBillList() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.searchValue,
        AppointmentBillID: that.AppointmentBillID,
        StartDate: that.SearchData != null ? that.SearchData[0] : "",
        EndDate: that.SearchData != null ? that.SearchData[1] : "",
        Type: "",
        Status: that.statusValue,
      };
      API.appointmentBillList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取预约详情
    getAppointmentBillInfo() {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.AppointmentInfoRuleForm.ID,
      };
      API.appointmentBillInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            var obj = Object.assign({}, res.Data);
            var appointmentDate = obj.AppointmentDate.trim().split(" ");
            if (obj.Project.length > 0) {
              obj.Project.forEach((val) => {
                val.Name = val.ProjectName;
                val.ID = val.ProjectID;
              });
              that.appointmentProjectList = obj.Project;
            } else {
              that.appointmentProjectList = [];
            }

            that.AppointmentInfoRuleForm.Status = obj.Status;
            that.AppointmentInfoRuleForm.CustomerID = obj.CustomerID;
            that.AppointmentInfoRuleForm.EmployeeID = obj.EmployeeID;
            that.AppointmentInfoRuleForm.Type = obj.Type;
            that.AppointmentInfoRuleForm.AppointmentDate = appointmentDate[0];
            that.AppointmentInfoRuleForm.AppointmentTime = appointmentDate[1];
            that.AppointmentInfoRuleForm.Period = obj.Period;
            that.AppointmentInfoRuleForm.Remark = obj.Remark;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 修改预约
    appointmentBillUpdate() {
      var that = this;
      var appointmentProjectList = [];
      that.loading = true;
      that.appointmentProjectList.forEach((val) => {
        var obj = {};
        if (val.ProjectID) {
          obj.ProjectID = val.ProjectID;
        } else {
          obj.ProjectID = val.ID;
        }
        appointmentProjectList.push(obj);
      });
      var params = Object.assign({}, that.AppointmentInfoRuleForm);
      // + ' ' + that.AppointmentInfoRuleForm.AppointmentTime
      params.AppointmentDate =
        that.AppointmentInfoRuleForm.AppointmentDate +
        " " +
        that.AppointmentInfoRuleForm.AppointmentTime;
      params.Project = appointmentProjectList;
      API.appointmentBillUpdate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (that.cancelState) {
              this.$message({
                type: "success",
                message: "取消成功",
              });
              that.cancelState = false;
            } else {
              this.$message({
                type: "success",
                message: "修改成功",
              });
            }
            that.appointmentDialogShow = false;
            if (that.handleIndex == "0") {
              that.appointmentBillAll();
            } else {
              that.getAppointmentBillList();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取预约配置
    getAppointmentConfig() {
      var that = this;
      that.loading = true;
      var params = {};
      var totalTime = 8 * 60; // 总时长
      var interval = 0; // 时间间隔
      var num = 0;
      var timeArr = [];
      var timeTotal = 0;
      var hours = 0;
      var minutes = 0;
      var period = 0;
      var dataTime = [];
      APIConfig.appointmentConfig(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.StartTime = res.Data.StartTime;
            that.EndTime = res.Data.EndTime;
            that.Period = "00:" + res.Data.Period;
            that.interval = res.Data.Period;
            that.IsMustEmployee = res.Data.IsMustEmployee;
            interval = res.Data.Period;

            var number = parseInt(60 / res.Data.Period);
            for (let i = 0; i <= number - 1; i++) {
              period += res.Data.Period;
              dataTime.push(period);
            }
            that.periodArr = dataTime;

            that.getDateTimerShaft(
              res.Data.StartTime,
              res.Data.EndTime,
              res.Data.Period
            );
            that.getDateAppointmentIntervalList(res.Data.Period);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    // 获取时间 时间轴 数据
    getDateTimerShaft(startTime, endTime, period) {
      var that = this;
      var currentTimestamp = new Date().getTime;
      var startTimestamp = date
        .convertDateFromString(that.currentDate + " " + startTime)
        .getTime(); //开始的时间戳
      var endTimestamp = date
        .convertDateFromString(that.currentDate + " " + endTime)
        .getTime(); // 结束的时间戳
      var periodTimestamp = Number(period) * 60 * 1000; // 间隔的毫秒

      that.tempdateTimeList = [];
      for (
        var index = startTimestamp;
        index <= endTimestamp;
        index += periodTimestamp
      ) {
        var isSelect = index > currentTimestamp ? true : false;
        that.dataTime.push({
          dateTimestamp: index, //时间戳
          timeStr: date.formatDate.format(new Date(index), "hh:mm"), // 时间轴显示字符串
          isSelect: isSelect,
          isShowAdd: false,
          time: date.formatDate.format(new Date(index), "hh:mm"),
        });
      }
      that.appointmentBillAll();
    },
    /** 获取 预约时长  */
    getDateAppointmentIntervalList(period) {
      var that = this;
      var startTimestamp = date
        .convertDateFromString(that.currentDate + " " + "00:" + period)
        .getTime();
      var endTimestamp = date
        .convertDateFromString(that.currentDate + " " + "08:00")
        .getTime();
      var periodTimestamp = Number(period) * 60 * 1000; // 间隔的毫秒
      var interValue = period;
      for (
        var index = startTimestamp;
        index <= endTimestamp;
        index += periodTimestamp
      ) {
        if (interValue < 60) {
          that.timeArr.push({
            time: date.formatDate.format(new Date(index), "mm分钟"), // 时间轴显示字符串
            value: interValue,
          });
        } else {
          that.timeArr.push({
            time: date.formatDate.format(new Date(index), "h小时mm分钟"), // 时间轴显示字符串
            value: interValue,
          });
        }
        interValue += period;
      }
    },
    // 创建预约
    createAppointment() {
      var that = this;
      var appointmentProjectList = [];
      var obj = {};
      var params = {};

      // var appointmentDate = date.formatDate.format(
      //   new Date(that.AppointmentInfoRuleForm.AppointmentDate),
      //   "YYYY-MM-DD"
      // );
      var appointmentDate = that.AppointmentInfoRuleForm.AppointmentDate;
      var AppointmentTime = that.AppointmentInfoRuleForm.AppointmentTime;
      var AppointmentDate = appointmentDate + " " + AppointmentTime;

      that.loading = true;

      obj.EmployeeID = that.AppointmentInfoRuleForm.EmployeeID;
      obj.CustomerID = that.CustomerID;
      obj.AppointmentDate = AppointmentDate;
      obj.Period = that.AppointmentInfoRuleForm.Period;
      obj.Remark = that.AppointmentInfoRuleForm.Remark;
      obj.Type = that.AppointmentInfoRuleForm.Type;

      that.appointmentProjectList.forEach((val) => {
        var obj = {};
        obj.ProjectID = val.ID;
        appointmentProjectList.push(obj);
      });
      params = Object.assign({}, obj);
      params.Project = appointmentProjectList;

      API.appointmentBillCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "成功创建预约",
              duration: 2000,
            });
            that.appointmentDialogShow = false;
            if (that.handleIndex == "0") {
              that.appointmentBillAll();
            } else {
              that.getAppointmentBillList();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 保存预约
    saveAppointment() {
      var that = this;
      var appointmentDetailsTitleState = that.appointmentDetailsTitleState;
      var AppointmentTime = that.AppointmentInfoRuleForm.AppointmentTime;
      if (that.appointmentDialogShow) {
        that.$refs["AppointmentInfoRuleForm"].validate((valid) => {
          if (valid) {
            if (
              AppointmentTime == undefined ||
              AppointmentTime == null ||
              AppointmentTime == ""
            ) {
              this.$message({
                type: "error",
                message: "请选择预约时间",
              });
            } else {
              if (appointmentDetailsTitleState == 0) {
                that.appointmentBillUpdate();
              } else {
                that.createAppointment();
              }
              // if (that.appointmentProjectList.length > 0) {
              //     if (appointmentDetailsTitleState == 0) {
              //         that.appointmentBillUpdate();
              //     } else {
              //         that.createAppointment();
              //     }
              // } else {
              //     this.$message({
              //         type: 'error',
              //         message: '请选择预约项目'
              //     });
              // }
            }
          }
        });
      } else {
        if (appointmentDetailsTitleState == 0) {
          that.appointmentBillUpdate();
        } else {
          that.createAppointment();
        }
        // if (that.appointmentProjectList.length > 0) {
        //     if (appointmentDetailsTitleState == 0) {
        //         that.appointmentBillUpdate();
        //     } else {
        //         that.createAppointment();
        //     }
        // } else {
        //     this.$message({
        //         type: 'error',
        //         message: '请选择预约项目'
        //     });
        // }
      }
    },
    // 获取可预约员工列表
    getEmployeeList() {
      var that = this;
      that.loading = true;
      var params = {};
      API.getEmployeeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.employeeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsProject) {
          data[i].PID = "1" + data[i].ID;
        } else {
          data[i].PID = "0" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 获取项目列表
    getProjectList() {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
      };
      API.getProjectList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.setRecursion(res.Data);
            that.projectList = Enumerable.from(res.Data)
              .where((i) => {
                if (!i.IsProject) {
                  i.Child = Enumerable.from(i.Child)
                    .where((i) => {
                      return !i.IsProject && i.Child.length > 0;
                    })
                    .toArray();
                }
                return !i.IsProject && i.Child.length > 0;
              })
              .toArray();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    // 前一天
    upClick: function() {
      var that = this;
      var currentDate = that.currentDate;
      var preDate = new Date(
        date.convertDateFromString(currentDate).getTime() - 24 * 60 * 60 * 1000
      );
      that.currentDate = date.formatDate.format(
        new Date(preDate),
        "YYYY-MM-DD"
      );
      that.appointmentBillAll();
    },
    // 后一天
    downClick: function() {
      var that = this;
      var currentDate = that.currentDate;
      var nextDate = new Date(
        date.convertDateFromString(currentDate).getTime() + 24 * 60 * 60 * 1000
      );
      that.currentDate = date.formatDate.format(
        new Date(nextDate),
        "YYYY-MM-DD"
      );
      that.appointmentBillAll();
    },
    // 日期改变
    getAppointmentBillAll() {
      var that = this;
      var currentDate = dayjs(that.currentDate).format("YYYY-MM-DD");

      // date.formatDate.format(
      //   date.convertDateFromString(that.currentDate),
      //   "YYYY-MM-DD"
      // );
      that.currentDate = currentDate;
      that.appointmentBillAll();
    },
    // 获取预约信息
    appointmentBillAll() {
      var that = this;
      that.loading = true;
      var params = {
        AppointmentDate: that.currentDate,
      };
      API.appointmentBillAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            // var tempEmpList = Enumerable.from(res.Data).doAction((item,index)=>{
            //  let AppointmentItems = Enumerable.from(item.Appointment).doAction((AppointmentItem,index)=>{
            //  console.log("appointmentBillAll -> AppointmentItem", AppointmentItem)

            //     var startTime = date.convertDateFromString(AppointmentItem.AppointmentDate).getTime()
            //     var endTime = new Date(startTime + AppointmentItem.Period * 60 * 1000).getTime()

            //     let count = Enumerable.from(item.Appointment).where(i=>{
            //       let tempStartTime = date.convertDateFromString(i.AppointmentDate).getTime()
            //       let tempEndTime = new Date(startTime + i.Period * 60 * 1000).getTime()

            //       return startTime > tempEndTime &&
            //     }).count()

            //     AppointmentItem.count = count
            //     console.log("appointmentBillAll -> count", count)
            //   }).toArray()
            //   item.AppointmentItems = AppointmentItems

            // }).toArray();

            // console.log("appointmentBillAll -> tempEmpList", tempEmpList)

            that.scrollWidth = 150 * res.Data.length;
            var beforeTime = dayjs(that.currentDate).valueOf();
            // date
            //   .convertDateFromString(that.currentDate)
            //   .getTime();
            var currentDate = dayjs(dayjs().format("YYYY-MM-DD")).valueOf();
            // date
            //   .convertDateFromString(
            //     date.formatDate.format(new Date(), "YYYY-MM-DD")
            //   )
            //   .getTime();
            var currentTime = new Date().getTime();

            res.Data.forEach((p, rowindex) => {
              p.curr = [];
              that.dataTime.forEach((s) => {
                p.curr.push({
                  ID: "",
                  CustomerID: "",
                  CustomerName: "",
                  startTimestamp: dayjs(
                    that.currentDate + " " + s.time
                  ).valueOf(),
                  //  date
                  //   .convertDateFromString(that.currentDate + " " + s.time)
                  //   .getTime(),
                  StartTime: s.time,
                  EndTime: "",
                  endTimestamp: "",
                  Status: "",
                  height: "",
                  row: rowindex,
                  count: 0,
                  showModel: false,
                  remark: "",
                  EmployeeID: p.EmployeeID,
                  showState: false,
                  dividerState: false,
                  Disabled: true,
                  showDetails: false,
                  disableSelected: true,
                  left: 0,
                  width: 150,
                });
              });
              p.curr.forEach((m, timeIndex) => {
                m.height = 0;
                m.width = 150;
                m.left = 0;
                m.arr = [];
                if (m.startTimestamp > currentTime) {
                  m.Disabled = false;
                }
                /**    */
                p.Appointment.forEach((w, wIndex) => {
                  let AppointmentDate = w.AppointmentDate; // 2020-08-28 08：15
                  let endAppointmentDate = new Date(
                    date.convertDateFromString(w.AppointmentDate).getTime() +
                      w.Period * 60000
                  );
                  let StartTime = date.formatDate.format(
                    date.convertDateFromString(AppointmentDate),
                    "hh:mm"
                  );
                  let EndTime = date.formatDate.format(
                    endAppointmentDate,
                    "hh:mm"
                  );
                  w.StartTime = StartTime;
                  w.EndTime = EndTime;

                  w.StartTimes = date
                    .convertDateFromString(w.AppointmentDate)
                    .getTime();
                  w.EndTimes = new Date(endAppointmentDate).getTime();
                });
                p.Appointment.forEach((n, nIndex) => {
                  n.EmployeeID = p.EmployeeID;
                  n.EmployeeName = p.EmployeeName;
                  n.appointmentArr = [];
                  p.Appointment.forEach((s, sIndex) => {
                    if (
                      n.StartTimes >= s.EndTimes ||
                      n.EndTimes <= s.StartTimes
                    ) {
                      //
                    } else {
                      if (n.appointmentArr.length < 3) {
                        n.appointmentArr.push(s);
                      }
                    }
                  });
                  let width = 0;
                  let hasArr = [];
                  let totalLength = 0;
                  // 固定位置下标
                  n.appointmentArr.forEach((val, valIndex) => {
                    var arr = [];
                    if (val.indexNum == undefined || val.indexNum == null) {
                      n.appointmentArr.forEach((item) => {
                        if (
                          item.indexNum != undefined &&
                          item.indexNum != null
                        ) {
                          arr.push(item.indexNum);
                        }
                      });
                      // hasArr = arr;
                      // totalLength = arr.length;
                      var state = false;
                      if (arr.length == 0) {
                        val.indexNum = 0;
                      } else {
                        for (let i = 0; i <= 2; i++) {
                          for (let j = 0; j <= arr.length - 1; j++) {
                            if (arr[j] == i) {
                              state = false;
                              break;
                            } else {
                              state = true;
                            }
                          }
                          if (state) {
                            val.indexNum = i;
                            break;
                          }
                        }
                      }
                    }
                  });

                  var arr = [];
                  n.appointmentArr.forEach((item) => {
                    if (item.indexNum != undefined && item.indexNum != null) {
                      arr.push(item.indexNum);
                    }
                  });
                  hasArr = arr;
                  totalLength = arr.length;
                  // 预约块高度左边距计算
                  n.appointmentArr.forEach((val, valIndex) => {
                    if (n.appointmentArr.length <= 3) {
                      // Start
                      if (
                        val.width == undefined ||
                        val.width == null ||
                        val.width == 0
                      ) {
                        if (totalLength > 0) {
                          // 计算剩余空间
                          var state = false;
                          hasArr.forEach((val) => {
                            if (val == 1) {
                              state = true;
                            }
                          });
                          if (state) {
                            if (n.appointmentArr.length == 2) {
                              if (val.indexNum == 1) {
                                var remainWidth = 146;
                                n.appointmentArr.forEach((item) => {
                                  if (
                                    item.width != undefined &&
                                    item.width != null &&
                                    item.width != 0
                                  ) {
                                    remainWidth -= item.width;
                                  }
                                });
                                val.width = remainWidth;
                              } else {
                                val.width = 146 / 3;
                              }
                            } else {
                              val.width = 146 / 3;
                            }
                            // 左边距
                            if (val.indexNum == 0) {
                              val.left = 0;
                            } else {
                              val.left = val.indexNum * (146 / 3 + 2);
                            }
                          } else {
                            // start
                            if (n.appointmentArr.length == 2) {
                              var remainWidth = 146;
                              n.appointmentArr.forEach((item) => {
                                if (
                                  item.width != undefined &&
                                  item.width != null &&
                                  item.width != 0
                                ) {
                                  remainWidth -= item.width;
                                }
                              });
                              val.width = remainWidth;
                            } else {
                              val.width =
                                (150 - (n.appointmentArr.length - 1) * 2) /
                                n.appointmentArr.length;
                            }
                            // end
                            // val.width = (150 - (n.appointmentArr.length - 1) * 2) / n.appointmentArr.length;
                            // 左边距
                            if (val.indexNum == 0) {
                              val.left = 0;
                            } else {
                              val.left = val.indexNum * (val.width + 2);
                            }
                          }
                        } else {
                          if (n.appointmentArr.length == 3) {
                            val.width = 146 / 3;
                            if (val.indexNum == 0) {
                              val.left = 0;
                            } else {
                              val.left = val.indexNum * (146 / 3 + 2);
                            }
                          } else {
                            val.width =
                              (150 - (n.appointmentArr.length - 1) * 2) /
                              n.appointmentArr.length;
                            if (val.indexNum == 0) {
                              val.left = 0;
                            } else {
                              val.left = val.indexNum * (val.width + 2);
                            }
                          }
                        }
                      } else {
                        if (n.appointmentArr.length >= 3) {
                          val.width = 146 / 3;
                          if (val.indexNum == 0) {
                            val.left = 0;
                          } else {
                            val.left = val.indexNum * (val.width + 2);
                          }
                        }
                      }

                      // End
                    }
                    p.Appointment.forEach((item, itemIndex) => {
                      if (item.ID == val.ID) {
                        item.left = val.left;
                      }
                    });
                  });
                  // return;
                  p.Appointment.forEach((x, xIndex) => {
                    if (n.EndTimes == x.StartTimes && n.left == x.left) {
                      n.showBottom = true;
                    }
                  });

                  var minBeginTime = n.appointmentArr[0].StartTimes;
                  var maxEndTime = n.appointmentArr[0].EndTimes;
                  if (n.appointmentArr.length >= 1) {
                    n.appointmentArr.forEach((item) => {
                      if (minBeginTime > item.StartTimes) {
                        minBeginTime = item.StartTimes;
                      }
                      if (maxEndTime < item.EndTimes) {
                        maxEndTime = item.EndTimes;
                      }
                    });
                  }
                  if (
                    minBeginTime <= m.startTimestamp &&
                    m.startTimestamp < maxEndTime
                  ) {
                    m.disableSelected = false;
                  }
                  // End
                  // Begin
                  var currentArr = [];
                  if (currentArr.length == 0) {
                    n.appointmentArr.forEach((val) => {
                      if (val.StartTime == m.StartTime) {
                        currentArr.push(val);
                      }
                    });
                  }
                  currentArr.forEach((val) => {
                    let Period = Number(val.Period);
                    let time = (Period / that.interval) * 41;

                    let diff = time;
                    val.height = diff;
                    val.offIndex = timeIndex;
                  });
                  // END 2020-8-31

                  if (m.StartTime == n.StartTime) {
                    if (n.Project.length > 0) {
                      n.Project.forEach((val) => {
                        val.ID = val.ProjectID;
                        val.Name = val.ProjectName;
                      });
                    }
                    m.ID = n.ID;
                    m.CustomerID = n.CustomerID;
                    m.CustomerName = n.CustomerName;
                    m.StartTime = n.StartTime;
                    m.EndTime = n.EndTime;
                    m.Status = n.Status;
                    m.AppointmentDate = n.AppointmentDate;
                    m.Project = n.Project;
                    m.Type = n.Type;
                    m.Period = n.Period;
                    m.Remark = n.Remark;
                    m.EmployeeName = n.EmployeeName;
                    m.phoneNumber = n.phoneNumber;
                    m.left = n.left;
                    m.width = 150 / n.appointmentArr.length;
                    m.offIndex = timeIndex;
                    m.appointmentArr = n.appointmentArr;
                    m.currentArr = currentArr;
                    m.showBottom = n.showBottom ? n.showBottom : false;
                    //计算高度
                    let time = (Number(m.Period) / that.interval) * 41;
                    let diff = time;
                    m.height = diff;
                  }
                });
              });
            });

            // 时间线展示判断
            var index = 0;
            // if(beforeTime == currentTime){
            for (let i = 0; i <= res.Data[0].curr.length - 1; i++) {
              if (!res.Data[0].curr[i].Disabled) {
                res.Data[0].curr[i].dividerState = true;
                index = i;
                break;
              }
            }
            // }
            // 时间线长度
            var length = res.Data.length;
            var width = length * 150 + "px";
            that.dividerWidth = width;
            this.$nextTick(() => {
              // 滚动到指定位置
              var right_div2 = document.querySelector(".right_div2");
              if (beforeTime == currentDate) {
                right_div2.scrollTo(0, 41 * index);
              } else {
                right_div2.scrollTo(0, 0);
              }
            });
            that.empList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getAppointmentBillList();
    },
    // 监听滚动
    changeScroll: function(event) {
      var top = event.target.scrollTop;
      var left = event.target.scrollLeft;
      document.querySelector(".left_div2").scrollTop = top;
      document.querySelector(".right_div1").scrollLeft = left;
    },
    // 修改预约状态
    updateStatus(data, type) {
      var that = this;
      var item = data;
      that.AppointmentInfoRuleForm.ID = item.ID;
      that.AppointmentInfoRuleForm.CustomerID = item.CustomerID;
      that.AppointmentInfoRuleForm.EmployeeID = item.EmployeeID;
      let appointmentDates = item.AppointmentDate.split(" ");
      that.AppointmentInfoRuleForm.AppointmentDate = appointmentDates[0];
      that.AppointmentInfoRuleForm.AppointmentTime = appointmentDates[1];
      that.AppointmentInfoRuleForm.Type = item.Type;
      // that.AppointmentInfoRuleForm.Status = item.Status;
      that.AppointmentInfoRuleForm.Period = item.Period;
      that.AppointmentInfoRuleForm.Remark = item.Remark;

      that.customerName = item.CustomerName;
      that.appointmentProjectList = item.Project;
      that.CustomerID = item.CustomerID;

      that.appointmentDetailsTitleState = 0;
      if (type == 0) {
        this.$confirm("确定要取消预约吗？?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.AppointmentInfoRuleForm.Status = 30;
            that.cancelState = true;
            that.saveAppointment();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        this.$confirm("确认已完成?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.AppointmentInfoRuleForm.Status = 20;
            that.cancelState = false;
            that.saveAppointment();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    // 修改预约
    turnStaff(item) {
      var that = this;
      var item = item;
      if (
        item.EmployeeID == "" ||
        item.EmployeeID == undefined ||
        item.EmployeeID == null
      ) {
        that.IsMustEmployee = false;
      } else {
        that.IsMustEmployee = true;
      }
      that.AppointmentInfoRuleForm.ID = item.ID;
      that.AppointmentInfoRuleForm.CustomerID = item.CustomerID;
      that.AppointmentInfoRuleForm.EmployeeID = item.EmployeeID;
      let AppointmentDate = item.AppointmentDate.split(" ");
      that.AppointmentInfoRuleForm.AppointmentDate = AppointmentDate[0];
      that.AppointmentInfoRuleForm.AppointmentTime = AppointmentDate[1];
      that.AppointmentInfoRuleForm.Type = item.Type;
      that.AppointmentInfoRuleForm.Status = item.Status;
      that.AppointmentInfoRuleForm.Period = item.Period;
      that.AppointmentInfoRuleForm.Remark = item.Remark;

      that.customerName = item.CustomerName;
      that.appointmentProjectList = item.Project;
      that.CustomerID = item.CustomerID;

      that.appointmentDetailsTitleState = 0;
      that.appointmentDialogShow = true;
    },
    // 点击添加预约
    clickAddAppointment(i) {
      var that = this;
      if (
        i.EmployeeID == "" ||
        i.EmployeeID == undefined ||
        i.EmployeeID == null
      ) {
        that.IsMustEmployee = false;
      } else {
        that.IsMustEmployee = true;
      }
      if (i.height <= 0 && i.showState) {
        that.addAppointment(1, i);
      }
    },
    // 鼠标移入事件
    mouseOver(col, row, item, i) {
      var that = this;
      var currentDate = new Date();
      var currentHours = currentDate.getHours();
      var currentMinutes = currentDate.getMinutes();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth() + 1;
      var currentDay = currentDate.getDate();
      var todayDate =
        currentYear +
        "-" +
        (currentMonth >= 10 ? currentMonth : "0" + currentMonth) +
        "-" +
        (currentDay >= 10 ? currentDay : "0" + currentDay);
      var beforeTime = date.convertDateFromString(that.currentDate).getTime();
      var currentTime = date.convertDateFromString(todayDate).getTime();

      if (beforeTime == currentTime) {
        var beginNum = parseInt(that.StartTime.split(":")[0]);
        var startMinutes = parseInt(that.StartTime.split(":")[1]);
        var endNum = parseInt(that.EndTime.split(":")[0]);
        var endMinutes = parseInt(that.EndTime.split(":")[1]);

        var remainder =
          ((endNum - beginNum) * 60 + (60 - startMinutes + endMinutes)) %
          that.interval;

        var rowNumber = 0;
        rowNumber =
          (currentHours - beginNum - 1) * that.periodArr.length +
          Math.ceil(
            Math.abs(60 - startMinutes + currentMinutes) / that.interval
          ) -
          1;
        if (i.disableSelected) {
          if (row > rowNumber) {
            i.showState = true;
          }
        }
      } else if (beforeTime > currentTime) {
        if (i.disableSelected) {
          i.showState = true;
        }
      }
    },
    // 鼠标移出事件
    mouseLeave(col, row, item, i) {
      var that = this;

      var currentDate = new Date();
      var currentHours = currentDate.getHours();
      var currentMinutes = currentDate.getMinutes();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth() + 1;
      var currentDay = currentDate.getDate();
      var todayDate =
        currentYear +
        "-" +
        (currentMonth >= 10 ? currentMonth : "0" + currentMonth) +
        "-" +
        currentDay;

      var beforeTime = date.convertDateFromString(that.currentDate).getTime();
      var currentTime = date.convertDateFromString(todayDate).getTime();
      if (beforeTime == currentTime) {
        var beginNum = parseInt(that.StartTime.split(":")[0]);
        var startMinutes = parseInt(that.StartTime.split(":")[1]);
        var endNum = parseInt(that.EndTime.split(":")[0]);
        var endMinutes = parseInt(that.EndTime.split(":")[1]);

        var remainder =
          ((endNum - beginNum) * 60 + (60 - startMinutes + endMinutes)) %
          that.interval;

        var rowNumber = 0;

        rowNumber =
          (currentHours - beginNum - 1) * that.periodArr.length +
          Math.ceil(
            Math.abs(60 - startMinutes + currentMinutes) / that.interval
          ) -
          1;

        if (i.disableSelected) {
          if (row > rowNumber) {
            i.showState = false;
          }
        }
      } else if (beforeTime > currentTime) {
        i.showState = false;
      }
    },
    // 看板信息 色块 鼠标移入事件 展示弹框
    // showDiv(val, n, index)
    showDiv(i, n, colIndex) {
      var that = this;
      that.isContinueShow = true;
      that.appointDetails = i;
      that.continueIndex = colIndex;

      if (colIndex === that.empList.length - 1) {
        that.position.x = colIndex * 150 - 250;
        that.position.y =
          that.appointDetails.offIndex * 40 + that.appointDetails.height * 0.3;
      } else {
        that.position.x =
          colIndex * 150 +
          that.appointDetails.left +
          that.appointDetails.width +
          10;
        that.position.y =
          that.appointDetails.offIndex * 40 + that.appointDetails.height * 0.3;
      }
    },
    /**    */
    popper__arrow(style) {
      return style;
    },

    // 看板信息 色块 鼠标移出事件隐藏弹框
    hideDiv(i, n) {
      var that = this;
      // setTimeout(() => {
      //   if (!that.isContinueShow) {
      // i.showDetails = false;
      //   }
      // }, 100);
      // that.appointDetails.showDetails = false;
      that.isContinueShow = false;
    },
    // 鼠标移入 预约详情弹窗
    onMouseenterDetailView() {
      var that = this;
      that.isContinueShow = true;
    },
    // 鼠标移出 预约详情弹窗
    onMouseleaveDetailView() {
      var that = this;
      that.isContinueShow = false;
    },
    // 开单按钮点击事件
    clickPutOrder() {
      var that = this;
      that.appointmentDialogShow = false;
      that.$router.push({
        path: "/Order/Bill",
        name: "Bill",
        params: { customerID: that.CustomerID },
      });
    },
    // 取消预约
    cancelAppointment() {
      var that = this;
      this.$confirm("确定要取消预约吗？?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.AppointmentInfoRuleForm.Status = 30;
          that.cancelState = true;
          that.saveAppointment();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  watch: {
    filterText(val) {
      var that = this;
      that.$refs.treeRef.filter(val);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.openBillState = permission.routerPermission("/Order/Bill");
      if (from.fullPath == "/iBeautyCustomer/Customer") {
        if (
          to.params.CustomerID == undefined ||
          to.params.CustomerID == null ||
          to.params.CustomerID == ""
        ) {
          return;
        } else {
          vm.appointmentDetailsTitleState = 1;
          vm.AppointmentInfoRuleForm.AppointmentDate = vm.currentDate;
          vm.customerName = to.params.customerName;
          vm.CustomerID = to.params.CustomerID;
          vm.AppointmentInfoRuleForm.EmployeeID = "";
          vm.AppointmentInfoRuleForm.AppointmentTime = "";
          vm.AppointmentInfoRuleForm.Type = "10";
          vm.AppointmentInfoRuleForm.Status = "";
          vm.AppointmentInfoRuleForm.Period = vm.timeArr[0].value;
          vm.AppointmentInfoRuleForm.Remark = "";
          vm.appointmentProjectList = [];
          vm.appointmentDialogShow = true;
        }
      }
    });
  },
  mounted() {
    var that = this;

    var time = new Date();
    that.currentDate = date.formatDate.format(new Date(time), "YYYY-MM-DD");

    var right_div = document.querySelector(".right_div");
    var left_div = document.querySelector(".left_div");
    var height = right_div.offsetHeight + 42;
    left_div.style.height = height + "px";

    that.employeeData(); // 客户所属顾问
    that.CustomerSourceData(); // 客户来源
    that.CustomerLevelData(); // 顾客等级
    that.getAppointmentBillList(); // 预约列表
    that.getAppointmentConfig(); // 预约配置
    that.getEmployeeList(); // 可预约员工列表
    that.getProjectList(); // 项目列表
  },
};
</script>

<style lang="scss">
.AppointmentView {
  .appointmentDesClass {
    right: 100px;
    top: 10px;
    z-index: 1;
    width: 500px;
  }
  .addAppointmentBtn {
    right: 15px;
    top: 10px;
    z-index: 1;
  }
  .container_out ::-webkit-scrollbar {
    // display: none;
  }

  .right_item {
    background: #f8f8f8;
    // overflow-y: auto;
  }

  .project_item {
    height: 100px;
    /*height: 70%;*/
    overflow-y: scroll;
  }

  .checked_group {
    width: 100%;
  }

  .normal_right {
    border-right: 1px solid #c0c4cc;
    border-bottom: 1px solid #c0c4cc;
    border-top: 1px solid #c0c4cc;
    font-size: 14px;
    padding: 5px 20px;
    color: #333;
    height: 40px;
    line-height: 40px;
  }

  .normal_left {
    border-left: 1px solid #c0c4cc;
    border-bottom: 1px solid #c0c4cc;
    border-top: 1px solid #c0c4cc;
    font-size: 14px;
    padding: 5px 20px;
    color: #333;
    height: 40px;
    line-height: 40px;
  }

  .squareBlock_orange {
    width: 20px;
    height: 20px;
    background: #fdf4fd;
  }

  .squareBlock_gray {
    width: 20px;
    height: 20px;
    background: #f2f2f2;
  }

  .squareBlock_purple {
    width: 20px;
    height: 20px;
    background: #cc99cc;
  }

  .squareBlock_green {
    width: 20px;
    height: 20px;
    background: #91c47e;
  }

  .arrow_box {
    width: 30px;
    height: 30px;
  }

  .tdd {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    z-index: 10;
    position: relative;
  }

  .container {
    margin-top: 5px;
    background-color: #fff;
    overflow: auto;
    font-size: 12px;
    .back_tab {
      border-top: 1px solid #eee;
      /*background-color: #F7E7FF;*/
    }
    .left_div2 {
      .table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border-spacing: 0;
        .td {
          /*border-right: 1px solid #ddd;*/
          word-break: break-all;
          word-wrap: break-word;
          height: 41px;
          width: 70px;
          line-height: 41px;
          overflow: hidden;
          text-align: center;
        }
      }
    }

    .left_div {
      width: 70px;
      float: left;
      border-right: 1px solid #eee;
      .td {
        /*border-top: 1px solid #ddd;*/
        /*border-right: 1px solid #ddd;*/
        /*border-bottom: 1px solid #ddd;*/
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
      .left_div1 {
        width: 100%;
        .td {
          border-top: 1px solid #eee;
          /*height: 41px;*/
        }
        /*overflow: hidden;*/
      }
      .left_div2 {
        margin-top: 18px;
        width: 100%;
        overflow: hidden;
        height: calc(100vh - 270px);
        left-table2 {
          margin-bottom: 4px;
        }
      }
    }
    .right_div {
      width: calc(100% - 71px);
      float: left;
      overflow: hidden;
      .right_div1 {
        width: 100%;
        overflow: hidden;
        .right_table1 {
          display: flex;
          .tr_div {
            line-height: 40px;
          }
        }
      }
      .right_div2 {
        width: 100%;
        overflow: auto;
        height: calc(100vh - 270px);
        .right_table2 {
          display: flex;
          height: 100%;
          position: relative;
          .appointment_details {
            background: #fff;
            width: 220px;
            position: absolute;
            z-index: 666;
            box-shadow: 2px 2px 3px 3px #eeeeee;
            border-radius: 5px;
            border: 1px solid #f8f8f8;
          }
          // 箭头
          .popper__arrow_left:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            left: -10px;
            top: 10%;
            border-top: 10px solid transparent;
            border-right: 10px solid #ffffff;
            border-bottom: 10px solid transparent;
            border-radius: 2px;
          }

          .popper__arrow_right:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            right: -10px;
            top: 10%;
            border-top: 10px solid transparent;
            border-left: 10px solid #ffffff;
            border-bottom: 10px solid transparent;
            border-radius: 2px;
          }
        }
      }

      .tr_div {
        width: 150px;
        height: 100%;

        .td_div {
          height: 40px;
          /*line-height: 40px;*/
          text-align: center;
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;
          position: relative;
          width: 150px;
          .select_td_show {
            position: absolute;
            top: 0;
            width: 150px;
            background-color: #f2f2f2;
            -ms-word-break: break-word;
            word-break: break-word;
            /*color: green;*/
            /*z-index: 3;*/
            display: table;
          }
          .td_show {
            position: absolute;
            top: 0;
            width: 150px;
            background-color: #fdf4fd;
            -ms-word-break: break-word;
            word-break: break-word;
            border-left: 2px solid #f5b8f5;
            /*color: #4169E1;*/
            z-index: 1;
            display: table;
          }
          .td_show_1 {
            position: absolute;
            top: 0;
            width: 150px;
            background-color: #cc99cc;
            -ms-word-break: break-word;
            word-break: break-word;
            /*color: #4169E1;*/
            z-index: auto;
            display: table;
          }
        }
      }
      .tr_div:nth-child(1) {
        .td_div {
          /*border-left: 1px solid #ddd;*/
        }
      }
    }
    .add_appointment {
      background: #f4fbff;
      color: rgb(64, 158, 255);
      font-size: 12px;
      // cursor: pointer;
      line-height: 40px;
      height: 40px;
      // z-index: -100;
    }
    .normal_box {
      height: 40px;
      width: 100%;
    }
    .divider {
      position: absolute;
      top: 0;
      background: rgb(64, 158, 255);
      height: 2px;
      z-index: 99;
      /*width: 1500px;*/
    }
    #right2_td_div {
      background-color: rgba(242, 242, 242, 0.5);
    }
  }
  .line_height_38 {
    line-height: 35px;
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}

.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
</style>
